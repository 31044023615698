import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
// import { Layout } from "../_metronic/layout";

export function Routes() {
  const OnboardingRoutes = lazy(() => import("./modules/Onboarding/Routes"));

  return (
    <Switch>
      {/*<Layout>*/}
      <Redirect exact from="/" to="/onboarding" />
      <Route path="/onboarding" component={OnboardingRoutes} />
      {/*</Layout>*/}
      <Route path="/error" component={ErrorsPage} />
      <Redirect to="/error/error-v1" />
    </Switch>
  );
}
