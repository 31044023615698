// import { actionTypes } from "../app/modules/Auth/_redux/authRedux";
import { setApiError } from "../app/modules/ErrorHandler/_redux/errorsActions";
import axios from "axios";
// import { AUTH_BODY, IDENTITY_URL } from "../app/modules/Auth/_redux/authCrud";
import { xOptionalUserIdGenerator } from "../app/helper/functions";

export default function setupAxios(store) {
  let pending = [];
  let cancelToken = axios.CancelToken;
  let removePending = ever => {
    for (let p in pending) {
      if (
        pending[p].u === ever.url + "&" + ever.method &&
        pending[p].p === JSON.stringify(ever.params || {})
      ) {
        pending[p].f();
        pending.splice(p, 1);
      }
    }
  };
  let reCallCount = 0;
  let isRefreshing = false;
  let failedQueue = [];
  let blockCall = false;
  let modalState = false;

  // const processQueue = (error, token) => {
  //   failedQueue.forEach(prom => {
  //     if (error) {
  //       prom.reject(error);
  //     } else {
  //       prom.resolve(token);
  //     }
  //   });
  //
  //   failedQueue = [];
  // };

  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken },
        // users: { impersonateUser },
        modal: { show }
      } = store.getState();
      blockCall = false;

      // if (impersonateUser && config.method !== "get") {
      //   blockCall = true;
      //   return;
      // }

      if (show) {
        modalState = true;
      }

      config.baseURL = process.env.REACT_APP_API_URL;

      const xOptionalUserId =
        config.headers.xOptionalUserId || xOptionalUserIdGenerator();
      delete config.headers.xOptionalUserId;

      config.headers["x-optional-userid"] = xOptionalUserId;

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        config.headers.contentType = "application/json";
        config.headers.accessControlAllowOrigin = "*";
      }

      // if (config.url.includes("/identity/api/v1.0/oauth")) {
      //   config.headers["x-authenticated-userid"] = `${
      //     AUTH_BODY.institutionId
      //   }||${user?.id || user_id || AUTH_BODY.institutionId}`;
      // }

      removePending(config);
      config.cancelToken = new cancelToken(c => {
        pending.push({
          u: config.url + "&" + config.method,
          f: c,
          p: JSON.stringify(config.params || {})
        });
      });
      return config;
    },
    error => {
      console.log({ error });
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      removePending(response.config);
      isRefreshing = false;
      return response;
    },
    error => {
      // console.log({error});
      if (axios.isCancel(error)) {
        console.warn("Cancelled Duplicate Requests");
      } else {
        const originalRequest = error.config;
        // const {
        //   auth: { refreshToken }
        // } = store.getState();

        if (error.response) {
          if (
            error.response.status === 404 &&
            error.response.config.url.includes("pensieve")
          ) {
            return false;
          }

          if (
            error.response.status === 401 &&
            error.response?.data?.error === "invalid_token" &&
            !originalRequest._retry
          ) {
            if (isRefreshing) {
              return new Promise(function(resolve, reject) {
                failedQueue.push({ resolve, reject });
              })
                .then(token => {
                  originalRequest.headers.Authorization = `Bearer ${token}`;
                  return axios(originalRequest);
                })
                .catch(err => {
                  return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // return new Promise(function(resolve, reject) {
            //   axios
            //     .post(`${IDENTITY_URL}/oauth2/refreshtoken`, {
            //       grantType: "refresh_token",
            //       ...AUTH_BODY,
            //       refreshToken
            //     })
            //     .then(({ data }) => {
            //       const { access_token } = data;
            //       axios.defaults.headers.common["Authorization"] =
            //         "Bearer " + access_token;
            //       axios.defaults.headers["Authorization"] =
            //         "Bearer " + access_token;
            //       originalRequest.headers["Authorization"] =
            //         "Bearer " + access_token;
            //
            //       // store.dispatch({
            //       //   type: actionTypes.UpdateToken,
            //       //   payload: data
            //       // });
            //       processQueue(null, access_token);
            //       resolve(axios(originalRequest));
            //     })
            //     .catch(err => {
            //       processQueue(err, null);
            //       store.dispatch(
            //         setApiError({
            //           data: {
            //             message: "Your session has expired, please log in."
            //           }
            //         })
            //       );
            //       // store.dispatch({ type: actionTypes.Logout });
            //       reject(err);
            //     })
            //     .then(() => {
            //       isRefreshing = false;
            //     });
            // });
          } else {
            const responseURL = error.request?.responseURL || "";

            if (
              !responseURL.includes("oauth2/refreshtoken") &&
              !responseURL.includes("oauth/token") &&
              !responseURL.includes("users/ForgetPassword") &&
              !responseURL.includes("users/Activate") &&
              !responseURL.includes("oauth/OtpValidation") &&
              !modalState
            ) {
              store.dispatch(setApiError(error.response));
            }
          }
        } else {
          if (originalRequest) {
            let callType = originalRequest.url
              ? originalRequest.url.split("/").reverse()[0]
              : "";
            if (callType === "token" && reCallCount < 2) {
              reCallCount++;
              return axios(originalRequest);
            } else {
              reCallCount = 0;
              store.dispatch(
                setApiError({
                  data: { message: "Connection to the server failed." }
                })
              );
            }
          } else {
            store.dispatch(
              setApiError({
                data: {
                  message: blockCall || "Connection to the server failed."
                }
              })
            );
          }
        }
      }

      return Promise.reject(error);
    }
  );
}
