import { createSlice } from "@reduxjs/toolkit";

const initialErrorsState = {
  errors: [],         // errors: [ {status: undefined, message: undefined, headers: {}}]
};

export const errorsSlice = createSlice({
  name: "errors",
  initialState: initialErrorsState,
  reducers: {
    setApiError: (state, action) => {
        state.errors.push(action.payload)
    },
    clearApiError: (state) => {
      state.errors = [];
    }
  },
});
