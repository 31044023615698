import { errorsSlice } from './errorsSlice';

const {actions} = errorsSlice;

export const setApiError = (params) => (dispatch) => {
  dispatch(actions.setApiError(params));
};

export const clearApiError = () => (dispatch) => {
  dispatch(actions.clearApiError());
};
