export const screenState = {
  initiate: 0,
  basicInformation: 1,
  businessInformation: 2,
  shareholdersDetails: 3,
  financialIndicator: 4,
  topThreeBuyers: 5,
  topThreeSuppliers: 6,
  financingDetails: 7,
  documentChecklist: 8,
  final: 9
};

export const businessStep = {
  typeOfOrganisation: 0,
  sector: 1,
  lineOfBusiness: 2,
  purposeOfFinancing: 4
};
