import { createSlice } from "@reduxjs/toolkit";
import { businessStep, screenState } from "../../../../_custom/_helpers/enums";
import { businessLists } from "../../../../_custom/_helpers/staticFields";

const initialTemplateState = {
  listLoading: false,
  actionsLoading: false,
  basicInformation: {},
  businessInformation: {},
  currentScreenState: screenState.initiate,
  currentStepInBusiness: businessStep.typeOfOrganisation,
  currentListInBusiness: businessLists[businessStep.typeOfOrganisation],
  shareholdersDetails: [],
  financialIndicator: {},
  topThreeBuyers: [],
  topThreeSuppliers: [],
  financingDetails: {},
  documentChecklist: {}
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: "onboarding",
  initialState: initialTemplateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    screenStateUpdated: (state, action) => {
      state.currentScreenState = action.payload.screenState;
    },
    authenticated: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.basicInformation = action.payload.basicInformation;
    },
    otpVerified: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    dataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state[action.payload.key] = {
        ...state[action.payload.key],
        ...action.payload.value
      };
    }
  }
});
