import { createSlice } from "@reduxjs/toolkit";


const initialModalState = {
  hidden: false,
  isLoading : null,
  show: false,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastError: null,
  title: null,
  name: null,
  items : null,
  createMethod : null,
  updateMethod : null,
  onImport : null,
  url : null,
  entityCode: null
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "modal",
  initialState: initialModalState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    importExcelData: (state, action) => {
      const {title, name, items, createMethod, updateMethod, url, onImport, entityCode, isLoading} = action.payload;
      state.isLoading = isLoading;
      state.title = title;
      state.name = name;
      state.items = items;
      state.createMethod = createMethod;
      state.updateMethod = updateMethod;
      state.url = url;
      state.totalCount = Object.keys(items).length;
      state.onImport = onImport;
      state.entityCode = entityCode;
    },
    removeExcelData: (state) => {
      state.isLoading = null;
      state.title = null;
      state.name = null;
      state.items = null;
      state.createMethod = null;
      state.updateMethod = null;
      state.url = null;
      state.totalCount = 0;
      state.onImport = null;
      state.entityCode = null;
    },
    hideModal: (state) => {
      state.hidden = true;
    },
    visibleModal: (state) => {
      state.hidden = false;
    },
    showModal: (state) => {
      state.show = true;
    },
    closeModal: (state) => {
      state.show = false;
    }
  }
});
