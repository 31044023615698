import { slice } from "./importExcelSlice";

const {actions} = slice;


export const importExcelData = (params) => dispatch => {
  dispatch(actions.importExcelData(params));
};
export const removeExcelData = () => dispatch => {
  dispatch(actions.removeExcelData());
};
export const hiddenModal = () => dispatch => {
  dispatch(actions.hideModal());
};
export const visibleModal = () => dispatch => {
  dispatch(actions.visibleModal());
};
export const openModal = () => dispatch => {
  dispatch(actions.showModal());
};
export const closeModal = () => dispatch => {
  dispatch(actions.closeModal());
};
