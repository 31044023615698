import { businessStep } from "./enums";

export const codeRegexPattern = /[^a-zA-Z0-9\s.\-_]/g;

export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const steppers = [
  {
    priority: 1,
    name: "Initiate",
    code: "initiate",
    icon: "fas fa-rocket"
  },
  {
    priority: 2,
    name: "Basic Information",
    code: "basicInformation",
    icon: "far fa-id-badge"
  },
  {
    priority: 3,
    name: "Business Information",
    code: "businessInformation",
    icon: "far fa-building"
  },
  {
    priority: 4,
    name: "Shareholders Details",
    code: "shareholdersDetails",
    icon: "fas fa-people-carry"
  },
  {
    priority: 5,
    name: "Financial Indicators",
    code: "financialIndicators",
    icon: "fas fa-coins"
  },
  {
    priority: 6,
    name: "Top 3 Buyers",
    code: "top3Buyers",
    icon: "far fa-smile"
  },
  {
    priority: 7,
    name: "Top 3 Suppliers",
    code: "top3Suppliers",
    icon: "fas fa-store"
  },
  {
    priority: 8,
    name: "Financing Details",
    code: "financingDetails",
    icon: "far fa-money-bill-alt"
  },
  {
    priority: 9,
    name: "Documents",
    code: "documents",
    icon: "far fa-folder-open"
  }
  // {
  //   priority: 10,
  //   name: "Finalization",
  //   code: "finalization",
  //   icon: "fas fa-glass-cheers"
  // }
];

export const businessLists = {
  [businessStep.typeOfOrganisation]: [
    {
      title: "Sole Proprietorship",
      value: "SoleProprietorship",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Partnership",
      value: "Partnership",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Private Limited Company (Pvt. Ltd.)",
      value: "PrivateLimitedCompany",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Public Limited Company (Ltd.)",
      value: "PublicLimitedCompany",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  [businessStep.sector]: [
    {
      title: "Manufacturing",
      value: "Manufacturing",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Service",
      value: "Service",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Retail",
      value: "Retail",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Wholesaler",
      value: "Wholesaler",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  [businessStep.lineOfBusiness]: [],
  Manufacturing: [
    {
      title: "Textile Production",
      value: "TextileProduction",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Food processing",
      value: "FoodProcessing",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Automobile Manufacturing",
      value: "AutomobileManufacturing",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Electronics Assembly",
      value: "ElectronicsAssembly",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Pharmaceutical Products",
      value: "PharmaceuticalProducts",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  Service: [
    {
      title: "Information Technology (IT) Services",
      value: "InformationTechnologyServices",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Financial Services (Banking, Insurance)",
      value: "FinancialServices",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Healthcare Services (Hospitals, Clinics)",
      value: "HealthcareServices",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Educational Institutions (Schools, Universities)",
      value: "EducationalInstitutions",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Hospitality Services (Hotels, Restaurants)",
      value: "PharmaceuticalProducts",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  Agriculture: [
    {
      title: "Crop Farming (Wheat, Rice, Fruits, Vegetables)",
      value: "CropFarming",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Livestock Farming (Cattle, Poultry)",
      value: "LivestockFarming",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Dairy Farming",
      value: "DairyFarming",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Organic Farming",
      value: "OrganicFarming",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Aquaculture (Fish Farming)",
      value: "Aquaculture",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  Construction: [
    {
      title: "Residential Building Construction",
      value: "ResidentialBuildingConstruction",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Commercial & Industrial Building Construction",
      value: "CommercialAndIndustrialBuildingConstruction",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Infrastructure Projects (Roads, Bridges)",
      value: "InfrastructureProjects",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Real Estate Development",
      value: "RealEstateDevelopment",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Specialized Construction Activities (Plumbing, Electrical)",
      value: "Aquaculture",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  Retail: [
    {
      title: "Clothing & Apparel Stores",
      value: "ClothingAndApparelStores",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Grocery Stores",
      value: "GroceryStores",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Consumer Electronics Stores",
      value: "ConsumerElectronicsStores",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Online Retail Platforms",
      value: "OnlineRetailPlatforms",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Furniture & Home Decor Stores",
      value: "FurnitureAndHomeDecorStores",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  Wholesaler: [
    {
      title: "Food & Beverage Wholesalers",
      value: "FoodAndBeverageWholesalers",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Industrial supply wholesalers ",
      value: "IndustrialSupplyWholesalers",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Agricultural Produce Wholesalers",
      value: "AgriculturalProduceWholesalers",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Clothing & Textile Wholesalers",
      value: "ClothingAndTextileWholesalers",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "ConstructionMaterialsWholesalers ",
      value: "Construction Materials Wholesalers",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ],
  [businessStep.purposeOfFinancing]: [
    {
      title: "Running Finance",
      value: "RunningFinance",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Term Loans",
      value: "TermLoans",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Payment of Salaries & Wages",
      value: "PaymentOfSalariesAndWages",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Payment to Vendors",
      value: "PaymentToVendors",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Purchase of Fixed Assets",
      value: "PurchaseOfFixedAssets",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Purchase of Inventory",
      value: "PurchaseOfInventory",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    },
    {
      title: "Working Capital Requirement",
      value: "WorkingCapitalRequirement",
      icon:
        "https://assets-global.website-files.com/6085444bc1ab19c6fb0bd04f/6086ae038a8e6d9c3d683b8c_Icon%20Glass.svg"
    }
  ]
};

export const requiredDocuments = [
  {
    title: "Bank Statement",
    value: "BankStatement",
    icon: "fas fa-newspaper"
  },
  {
    title: "Utility Bills",
    value: "UtilityBills",
    icon: "fas fa-file-invoice-dollar"
  },
  {
    title: "Photograph of Business",
    value: "PhotographOfBusiness",
    icon: "fas fa-camera-retro"
  },
  {
    title: "ID Card/ Employer Letter",
    value: "IDCardOrEmployerLetter",
    icon: "fas fa-id-card-alt"
  }
];

export const dynamicKeys = {
  [businessStep.typeOfOrganisation]: "typeOfOrganisation",
  [businessStep.sector]: "sector",
  [businessStep.purposeOfFinancing]: "purposeOfFinancing"
};

export const dynamicHeadings = {
  [businessStep.typeOfOrganisation]: "Type of organization or legal structure",
  [businessStep.sector]: "Sector",
  [businessStep.purposeOfFinancing]: "Purpose of Financing"
};

export const documentChecklist = [
  {
    title: "National Identifier (CNIC) Front Side",
    value: "nationalIdentifierFrontSide"
  },
  {
    title: "National Identifier (CNIC) Back Side",
    value: "nationalIdentifierBackSide"
  },
  {
    title: "Bank Statement (Last 3 Months)",
    value: "bankStatement"
  },
  {
    title: "Utility Bills (Last 3 Months)",
    value: "utilityBills"
  },
  {
    title: "Photograph of Business",
    value: "photographOfBusiness "
  },
  {
    title: "Selfie",
    value: "selfie"
  },
  {
    title: "ID Card / Employer Letter ",
    value: "identityCardOrEmployerLetter"
  }
];
