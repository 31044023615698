import React from "react";
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function NotificationsContainer() {

  return (
    <>
      <ToastContainer
        position={"bottom-left"}
        autoClose={3500}
        hideProgressBar={true}
        theme='colored'
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        transition={Zoom}
        limit={3}
      />
    </>
  );
}
