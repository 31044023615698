import lodash from "lodash";

export const generateAcronym = (str = "N/A") => {
  if (str == null) str = "NA";

  const checkSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/g;

  if (checkSpecialCharacter.test(str)) {
    str = str.replace(/[^a-zA-Z ]/g, "");
  }

  return (/\s/g.test(str)
    ? str.charAt(0) + str.charAt(str.lastIndexOf(" ") + 1)
    : str.charAt(0)
  ).toUpperCase();
};

export const getNumberInCurrencyFormat = ({
  number = 0,
  locales = "en-GB",
  currencyFormat = "PKR",
  appendCurrency = false,
  isWholeNumber = false
}) => {
  if (number?.toString() === "-") {
    return "-";
  }
  if (number === "N/A" || number === null || number === "") {
    return number;
  }

  const formatter = new Intl.NumberFormat(locales, {
    style: "currency",
    currency: currencyFormat
  });
  return `${appendCurrency ? currencyFormat + " " : ""}${formatter
    .formatToParts(number ? number : isWholeNumber ? 0.0 : 0)
    .map(item =>
      (!isWholeNumber
      ? item?.type !== "currency" &&
        item?.type !== "literal" &&
        item?.type !== "nan"
      : item?.type !== "currency" &&
        item?.type !== "literal" &&
        item?.type !== "decimal" &&
        item?.type !== "fraction" &&
        item?.type !== "nan")
        ? item?.value
        : ""
    )
    .join("")}`;
};

export const getFormattedNumber = number =>
  number !== "N/A" || !number === null
    ? new Intl.NumberFormat("us-US").format(number)
    : number;

export const sortByAlphabeticalOrder = (list, sortKey = "name") =>
  (list || []).sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

export const uppercaseFirstLetter = word =>
  lodash.startCase(lodash.toLower(word));

export const passwordHelper = (helperText, min, max) => {
  helperText = helperText?.replace("{min}", min.toString());
  helperText = helperText?.replace("{max}", max.toString());
  return helperText;
};

//get the IP addresses associated with an account
export const getIPs = callback => {
  var ip_dups = {};
  var count = 0;

  //compatibility for firefox and chrome
  var RTCPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  // var useWebKit = !!window.webkitRTCPeerConnection;

  //bypass naive webrtc blocking using an iframe
  if (!RTCPeerConnection) {
    //NOTE: you need to have an iframe in the page right above the script tag
    //
    //<iframe id="iframe" sandbox="allow-same-origin" style="display: none"></iframe>
    //<script>...getIPs called in here...
    //
    var iframe = document.getElementById("iframe");
    var win = iframe.contentWindow;
    RTCPeerConnection =
      win.RTCPeerConnection ||
      win.mozRTCPeerConnection ||
      win.webkitRTCPeerConnection;
    // useWebKit = !!win.webkitRTCPeerConnection;
  }

  //minimal requirements for data connection
  var mediaConstraints = {
    optional: [{ RtpDataChannels: true }]
  };

  var servers = { iceServers: [{ urls: "stun:stun.services.mozilla.com" }] };

  //construct a new RTCPeerConnection
  var pc = new RTCPeerConnection(servers, mediaConstraints);

  function handleCandidate(candidate) {
    //match just the IP address
    var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
    var ip_addr = ip_regex.exec(candidate)?.[1];

    //remove duplicates
    if (ip_dups[ip_addr] === undefined) {
      if (ip_addr) {
        ++count;
        callback({
          ip: ip_addr,
          name:
            count === 1
              ? "Ethernet adapter vEthernet (Default Switch)"
              : count === 2
              ? "Ethernet adapter vEthernet (WSL)"
              : count === 3
              ? "Wireless LAN adapter Wi-Fi 2"
              : "Not Available"
        });
      }
    }

    ip_dups[ip_addr] = true;
  }

  //listen for candidate events
  pc.onicecandidate = function(ice) {
    //skip non-candidate events
    if (ice.candidate) handleCandidate(ice.candidate.candidate);
  };

  //create a bogus data channel
  pc.createDataChannel("");

  //create an offer sdp
  pc.createOffer(
    function(result) {
      //trigger the stun server request
      pc.setLocalDescription(
        result,
        function() {},
        function() {}
      );
    },
    function() {}
  );

  //wait for a while to let everything done
  setTimeout(function() {
    //read candidate info from local description
    var lines = pc.localDescription.sdp.split("\n");

    lines.forEach(function(line) {
      if (line.indexOf("a=candidate:") === 0) handleCandidate(line);
    });
  }, 1000);
};

export const getAllRoutes = list => {
  let updatedRoutes = [];
  (list || []).forEach(item => {
    updatedRoutes.push(item?.route);

    if (item?.children?.length) {
      item.children.forEach(child => {
        updatedRoutes.push(child?.route);
      });
    }
  });
  return updatedRoutes || [];
};

export const imageExists = async imgUrl => {
  if (!imgUrl) {
    return false;
  }

  const http = new XMLHttpRequest();

  http.open("HEAD", imgUrl, false);
  http.send();

  return http.status !== 404;
};
