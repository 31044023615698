/* Pagination Helprs */
import React from "react";

export const getPagesCount = (totalSize, sizePerPage) => {
  return Math.ceil(totalSize / sizePerPage);
};

export const getPages = (page, pagesCount, paginationSize) => {
  const result = [];
  if (!page) {
    return result;
  }

  if (pagesCount === 1) {
    result.push(1);
    return result;
  }

  if (pagesCount < page) {
    return result;
  }

  if (pagesCount < paginationSize + 1) {
    for (let i = 1; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (page === 1) {
    for (let i = 1; i < paginationSize + 1; i++) {
      if (i < pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  if (page === pagesCount) {
    for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  const shiftCount = Math.floor(paginationSize / 2);
  if (shiftCount < 1) {
    result.push(page);
    return result;
  }

  //
  if (page < shiftCount + 2) {
    for (let i = 1; i < paginationSize + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (pagesCount - page < shiftCount + 2) {
    for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i);
    }
  }
  result.push(page);
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pagesCount) {
      result.push(i);
    }
  }

  return result;
};

export function getHandlerTableChange(
  setQueryParams,
  uiProps = {},
  stages,
  usersDictionary,
  currentExternalSystemsDictionary
) {
  return (type, {page, sizePerPage, sortField, sortOrder, data, filters}) => {
    const pageNumber = page || 1;
    let isSort = type === 'sort';

    let finalFilters = {};
    Object.entries(filters || {}).forEach(([key, value]) => {
      finalFilters = {
        ...finalFilters,
        [key]: value?.filterVal
      }
    });

    setQueryParams((prev) => {
        if (type === "sort") {
          let sortedData = [...data];
          let fa = "";
          let fb = "";
          sortedData = sortedData.sort((a, b) => {

            if (sortField === "stage") {
              let code1 = (a?.stageDetails || []).find(item => item.isCurrent)?.stageCode || a?.nextStageCode || null;
              let code2 = (b?.stageDetails || []).find(item => item.isCurrent)?.stageCode || b?.nextStageCode || null;

              (stages || []).forEach(stage => {
                if (stage.code === code1) {
                  fa = (stage.name || "").toLowerCase();
                }
                if (stage.code === code2) {
                  fb = (stage.name || "").toLowerCase();
                }
              });
            }
            else if(sortField.includes("[")){
              let level=sortField.split("[");
              let level1=level[0];
              let level2=level[1].replace("]","");
              fa=a[level1][level2];
              fb=b[level1][level2];
            }
            else if (sortField === "assignedTo") {
              fa = (usersDictionary[a[sortField] || ""]?.name || "N/A").toString()?.toLowerCase();
              fb = (usersDictionary[b[sortField] || ""]?.name || "N/A").toString()?.toLowerCase();

            }
            else if (sortField.includes(".")) {
              let check = sortField.split(".")
               fa = (a[check[0]][check[1]] || "").toString().toLowerCase();
               fb = (b[check[0]][check[1]] || "").toString().toLowerCase();
            } else if (sortField === "currentExternalSystemCode") {
              fa = a.status === 1 ?
                "all external system completed" :
                (currentExternalSystemsDictionary[a[sortField] || ""]?.name || "N/A").toString()?.toLowerCase();
              fb = b.status === 1 ?
                "all external system completed" :
                (currentExternalSystemsDictionary[b[sortField] || ""]?.name || "N/A").toString()?.toLowerCase();

            } else {
              fa = (a[sortField] || "");
              fb = (b[sortField] || "");

              fa = isNaN(fa) ? String(fa).toLowerCase() : Number(fa);
              fb = isNaN(fb) ? String(fb).toLowerCase() : Number(fb);
            }
            if (fa < fb) {
              return (sortOrder === "desc") ? -1 : (sortOrder === "asc") ? 1 : 0
            }
            if (fa > fb) {
              return (sortOrder === "desc") ? 1 : (sortOrder === "asc") ? -1 : 0
            }
            return 0;
          });

          return {...prev, sortOrder, sortField, isSort, sortedData};
        } else if (type === "pagination") {
          return {...prev, pageNumber, pageSize: sizePerPage, isSort: null, sortOrder: null, sortField: null, sortedData: null}
        } else if (type === "filter") {
          return {pageNumber, pageSize: sizePerPage, category: uiProps?.queryParams?.category, ...finalFilters}
        } else {
          return prev
        }
      }
    );
  };
}

export function PleaseWaitMessage({entities}) {
  return <>{entities === null && <div>Please wait...</div>}</>;
}

export function NoRecordsFoundMessage({entities}) {
  const customersList = entities === null ? [] : entities;
  return (
    <>
      {customersList.length === 0 && entities !== null && (
        <div>No records found</div>
      )}
    </>
  );
}
